
function Disclaimer() {
    return (
        <div className="footerSection">
            <h1 className="text-center">Disclaimer For Satta King Fixed No</h1>
            <p>
                Read Our Website Disclaimer Here:
                Do you have any query related to our website and satta king result you can contact us at our 9354102807 or email us: jaipeerbaba8447@gmail.com.
            </p>
            <h1 className="text-center">Disclaimers For satta - king - fixed - no</h1>
            <p>
                All the details and information you are reading is the only purpose of providing people exact information and for helping people.We shared all reports and internal and external linking at our website.Our aim is for people to find details that they are looking for.
            </p>
            <h1 className="text-center">Consent</h1>
            <p>
                If you are reaching our website and reading information you agree to all privacy policies and terms.
            </p>
            <h1 className="text-center">Update</h1>
            <p>
                Whatever changes will be done by our team we will update here from time to time.
            </p>
        </div>








    )
}

export default Disclaimer