import React from 'react';
import { Routes, Route } from 'react-router-dom';
import AboutUs from './aboutUs';
import Disclaimer from './disclaimer';
import PrivacyPolicy from './privacyPolicy';

const AllFooterButtonPage = () => {
    return (
        <div className='pt-4'>
            <Routes>
                <Route path="/about-us" element={<AboutUs />} />
                <Route path="/disclaimer" element={<Disclaimer />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            </Routes>

        </div>
    );
};

export default AllFooterButtonPage;
