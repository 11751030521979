
function AboutUs() {
    return (
        <div className="footerSection">
            <h1 className="text-center">About Satta King Fixed No (SATTA KING)</h1>
            <p>
                People probably query about Satta King Online. How can we play satta games online and try our luck and become rich in minimum time? People love to talk about Satta Games and love to find out about Satta Games online. Satta games are like a trend in India. People are involved in Satta Games. We did not play Satta Games at our website but for users we shared with people for their help. Last year it's increasing day by day. Do you know why people love the <b>Satta King Game?</b> Because Satta gives you money like a magician who doesn't love money, everyone wants money.
                No efforts just try your luck in Satta King and earn money in some time. After trying your luck in Satta King you need to find out Satta King online website for getting results. No one wants to wait because no one has time and also wants to save their time. Our website is the best solution for them. We provide Fastest Satta King online live results. Our website is shared on a regular basis. If you want last year's specific month and day Satta King result you need to just select month date year.With in minute you will get Satta Result Online.
            </p>

            <p>
                First you need to find Satta Game online website for this just find Satta King Games Online at search engine. A list of pages will display who will give you a chance to play the satta king game result online. Next Step is find out Satta King live result website find out in search engine. Our website provides Fast Satta King Result Online. We shared the Satta King 2021 December Record Chart. We also shared previous years' SattaKing results.
            </p>

            <p>
                Why You Choose us for Getting Result,
                Our website is very old and you will get genuine and fastest results here.For any query we share our contact number you can whatsapp and get details about your query.
            </p>
        </div>
    )
}

export default AboutUs