import "./../App.css";
import { useNavigate } from "react-router-dom";

function FooterButton() {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div className="col-12">
      <div className="text-center footerContent">
        <h3 className="text-danger">SATTAKING IMPORTANT NOTE</h3>
        <p className="text-primary">
          यह साइट सिर्फ और सिर्फ मनोरंजन के लिए बनाई गई है । यह साइट सट्टे से
          जुड़ी किसी भी गतिविधि को बढ़ावा नहीं देती । सट्टा और जुआं जिस देश या
          राज्य में प्रतिबंधित है वहां के लोग हमारी साइट को ब्लॉक कर दें | किसी
          भी लाभ या हानि के लिए आप खुद जिम्मदार होंगे ।
        </p>
      </div>
      <div className="footerButton text-center text-white">
        <section>
          <div className="col-12">
            <div className="pt-3 pb-2">
              <div className="button-group pb-2">
                <button
                  className="col btn"
                  onClick={() => handleNavigation("/about-us")}
                >
                  ABOUT US
                </button>
                <button
                  className="col btn"
                  onClick={() => handleNavigation("/disclaimer")}
                >
                  DISCLAIMER
                </button>
                <button
                  className="col btn"
                  onClick={() => handleNavigation("/privacy-policy")}
                >
                  PRIVACY POLICY
                </button>
                <button
                  className="col btn"
                  onClick={() => handleNavigation("/sitemap")}
                >
                  SITEMAP
                </button>
              </div>

              <div className="linkGroup">
                <a href="" title="Satta King">
                  {" "}
                  Satta King
                </a>{" "}
                |
                <a href="" title="Satta King darbar">
                  {" "}
                  Satta King darbar
                </a>{" "}
                |
                <a href="" title="SATTA KING RECORD CHART 2018">
                  {" "}
                  Satta King 2018
                </a>{" "}
                |
                <a href="" title="Satta King Record Chart 2019">
                  {" "}
                  Satta King 2019
                </a>{" "}
                |
                <a href="" title="Satta King Record Chart 2020">
                  {" "}
                  Satta King 2020
                </a>{" "}
                |
                <a href="" title="Satta King 2021">
                  {" "}
                  Satta King 2021
                </a>{" "}
                |
                <a href="" title="Satta King 2022">
                  {" "}
                  Satta King 2022
                </a>{" "}
                |
                <a href="" title="Satta King 2023">
                  {" "}
                  Satta King 2023
                </a>{" "}
                |
                <a href="" title="Satta King 2024">
                  {" "}
                  Satta King 2024
                </a>{" "}
                |
                <a href="" title="Satta King Record Chart">
                  {" "}
                  Satta King Record Chart
                </a>{" "}
                |
                <a href="" title="Satta King Record Chart">
                  {" "}
                  Satta King 786
                </a>{" "}
                |
                <a href="" title="Satta King Record Chart">
                  {" "}
                  Satta King leak
                </a>{" "}
                |
                <a href="" title="Satta King Record Chart">
                  {" "}
                  UP game king
                </a>{" "}
                |
                <a href="" title="Satta King Record Chart">
                  {" "}
                  Satta Live Result
                </a>{" "}
                |
                <a href="" title="Vip satta king">
                  {" "}
                  VIP Satta King
                </a>{" "}
                |
                <a href="" title="Satta King smart">
                  {" "}
                  Satta Smart King
                </a>{" "}
                |
                <a href="" title="Satta King online">
                  {" "}
                  Satta king online
                </a>
              </div>
            </div>
          </div>
        </section>
        <hr />
        <section className="copyright">
          Copyright © 2018-2023 - Satta King Fixed No.
        </section>
      </div>
    </div>
  );
}

export default FooterButton;
